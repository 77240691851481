/// <reference path="/dist/scripts/!shared/angular/angular-bundle.js"
/// <reference path="/dist/scripts/!shared/jquery/jquery-bundle.js"

(function (app) {
    'use strict';

    var myStorage = {
        'selectedActivities': "euroviaSelectedActivities",
        'radius': "euroviaRadius",
        'place': "euroviaPlace"
    };
    var isList = false;
    var currentMarker;
    var SMCL = false;
    var markerColor = 'red';
    if ($('#gmaps')) {
        markerColor = $('#gmaps').data('marker-color');
        if (!markerColor) markerColor = 'red';
    }

    app.run(['$rootScope', 'emap', 'geo', '$timeout', '$window', '$log', 'entitiesData', 'progress',
        function ($rootScope, emap, geo, $timeout, $window, $log, entitiesData, progress) {
            // 1. Obtain the data - entities and activities
            entitiesData.get().then(function (response) {
                $timeout(function () {
                    var data = response;
                    progress.set(1);
                    var myData = {
                        'allEntities': angular.fromJson(data.allEntities),
                        'allActivities': angular.fromJson(data.allActivities)
                    };
                    $rootScope.data = myData;
                    progress.set(2);
                    $rootScope.$apply();
                });
            });
            // 2. Initialise filters
            var defaultPlace = document.getElementById('defaultPlace').value;
            var location = { 'place': defaultPlace, 'radius': 100 };
            if (localStorage.getItem(myStorage.place) !== null) location.place = localStorage.getItem(myStorage.place);
            if (localStorage.getItem(myStorage.radius) !== null) location.radius = parseInt(localStorage.getItem(myStorage.radius));
            $rootScope.location = location;
            $rootScope.selectedActivities = angular.fromJson(localStorage.getItem(myStorage.selectedActivities));

            var openMapPopup = function (url) {
                $.get(url)
                    .done(function (data) {// success
                        $(".giwContainer").html(data);
                        $("#gmaps-info-window").fadeIn();
                    });
            };

            window.openMapPopup = openMapPopup;

            var debugWatch = function (id, oldVal, newVal) {
                $log.log('"' + id + '" watch(old, new):');
                $log.log(oldVal);
                $log.log(newVal);
                //debugger
            };

            $rootScope.$watch('location.place', function (newValue) {
                if (newValue) updatePoint(newValue);
            });

            var updatePoint = function (newLocation) {
                var geocoded = geo.geocode(newLocation);
                if (geocoded) { // if geolocation works (- mainly the site is not offline)
                    geocoded.then(function (response) {
                        if (response) {
                            var myPoint = response.geometry.location;
                            $timeout(function () {
                                $rootScope.location.point = myPoint;
                                $rootScope.$apply();
                            });
                        }
                    });
                }
            };

            $window.onbeforeunload = function (event) {
                if ($rootScope.location) {
                    if ($rootScope.location.place) {
                        localStorage.setItem(myStorage.place, $rootScope.location.place);
                        //$log.log("saved to local $rootScope.location: " + $rootScope.location);
                    }
                    if ($rootScope.location.radius) {
                        localStorage.setItem(myStorage.radius, $rootScope.location.radius);
                        //$log.log("saved to local $rootScope.radius:" + $rootScope.radius);
                    }
                }
                if ($rootScope.selectedActivities) {
                    localStorage.setItem(myStorage.selectedActivities, angular.toJson($rootScope.selectedActivities));
                    //$log.log("saved to local $rootScope.selectedActivities:" + $rootScope.selectedActivities);
                }
            };
        }]);

    app.service('helper', [function () {
        return {
            intersectsWith: function (source, target) {
                if (!source || source.constructor !== Array)
                    return false;
                var isTargetAnArray = (target && target.constructor === Array);
                var element = target;
                if (isTargetAnArray && target.length === 1) {
                    isTargetAnArray = false;
                    element = target[0];
                } else if (!isTargetAnArray) {
                    element = target;
                }
                var result = [];
                if (isTargetAnArray) {
                    result = source.filter(function (item) {
                        return target.indexOf(item) > -1;
                    });
                }
                else {
                    return source.indexOf(element) > -1;
                }
                return result.length > 0;
            },

            csvToIntArray: function (csvIntArray) {
                if (!csvIntArray) return null;
                var pureStringArray = csvIntArray.trim().replace(/^[,\[]|[,\]]$/gm, '');
                var result = pureStringArray.split(',').map(Number);
                if (result.length === 1) {
                    if (!isFinite(pureStringArray))
                        return null;
                }
                return result;
            },

            idsToNames: function (ids, objects, join, separator) {
                var idsMap = objects.reduce(function (result, o) {
                    result[o.id] = o.name;
                    return result;
                }, {
                });

                var myNames = ids.map(function (id) {
                    return idsMap[id];
                });

                if (join === true) {
                    if (!separator) { separator = ", "; }
                    return myNames.join(separator);
                }
                else {
                    return myNames;
                }
            }
        };
    }]);

    app.service("progress", [function () {
        var step = 100 / 7;
        var percent = 0;

        var set = function (data) {
            //$log.log(data);
            var d = new Date();
            var n = d.toString();

            $(".splashScreenMap").show();
            percent += step;
            //$log.log("Progress step percent: " + percent);

            var prev = $('.progress-bar').attr('aria-valuenow');
            if (prev < percent) {
                $('.progress-bar').css('width', percent + '%').attr('aria-valuenow', percent);
            }

            if (percent >= 100) {
                $(".splashScreenMap").show().delay(750).fadeOut(500);
            }
        };

        return {
            set: set
        };
    }]);

    app.service("infoWindow", function () {
        var showInfoWindow = function (bool) {
            if (bool) {
                $("#gmaps-info-window").fadeIn();
            } else {
                $("#gmaps-info-window").fadeOut();
            }
        };

        var closeInfoWindow = function (e) {
            e.preventDefault();
            showInfoWindow(false);
            if (currentMarker)
                currentMarker.setIcon("/dist/content/img/!shared/main/" + markerColor + "-marker.png");
            //Remove blur effect
            //$("#gmaps").removeClass("blurred");
        };

        return {
            show: showInfoWindow,
            close: closeInfoWindow
        };
    });

    //Geocoding is the process of converting addresses
    //(like "1600 Amphitheatre Parkway, Mountain View, CA")
    //into geographic coordinates (like lat 37.4; lng -122.08),
    //which you can use to place markers on a map, or position the map.
    //Reverse geocoding is the process of converting geographic coordinates
    //into a human-readable address.
    //The Google Maps Geocoding API's reverse geocoding service also lets you find
    //the address for a given place ID.

    app.service("geo", [function () {
        var emptyFunction = function () {
        };
        if (typeof google === 'undefined') {
            return {
                geocode: emptyFunction, reverseGeocode: emptyFunction
            };
        }

        var geocoder = new google.maps.Geocoder();
        return {
            geocode: function (address) {
                return $.Deferred(function (dfrd) {
                    geocoder.geocode({
                        'address': address
                    }, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            dfrd.resolve(results[0]);
                        } else {
                            dfrd.reject(new Error(status));
                        }
                    });
                }).promise();
            },
            reverseGeocode: function (location) {
                return $.Deferred(function (dfrd) {
                    geocoder.geocode({
                        'location': location
                    },
                        function (results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                dfrd.resolve(results[1]);
                            } else {
                                dfrd.reject(new Error(status));
                            }
                        });
                }).promise();
            }
        };
    }]);

    app.service('emap', ['progress', 'infoWindow', '$http', '$compile', '$rootScope', function (progress, infoWindow, $http, $compile, $rootScope) {
        var mapElement = document.getElementById('gmaps');
        if (!mapElement) return null;

        var references = document.getElementById('defaultCenter').value.split(',');

        var defaultCenter = {
            lat: parseFloat(references[0]), lng: parseFloat(references[1])
        };

        var defaultRadius = 100 * 1000; // 100 km
        var defaultColor = "#0000FF";

        var circle = new google.maps.Circle({
            center: defaultCenter,
            radius: defaultRadius,
            strokeColor: defaultColor,
            strokeOpacity: 0,
            strokeWeight: 2,
            fillColor: defaultColor,
            fillOpacity: 0,
        });

        var mapOptions = {
            center: circle.center,
            zoom: 4,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            streetViewControl: false,
            panControl: false,
            mapTypeControl: false,
            styles: [{
                "featureType": "all", "stylers": [{
                    "saturation": 0
                }, { "hue": "#e7ecf0" }]
            }, { "featureType": "road", "stylers": [{ "saturation": -70 }] }, {
                "featureType": "transit", "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "poi", "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "water", "stylers": [{ "visibility": "simplified" }, { "saturation": -60 }]
            }]
        };

        //[{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"administrative.locality","elementType":"labels","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"},{"visibility":"simplified"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"visibility":"simplified"},{"saturation":"-65"},{"lightness":"45"},{"gamma":"1.78"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"on"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.highway","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"saturation":"-33"},{"lightness":"22"},{"gamma":"2.08"}]},{"featureType":"transit.station.airport","elementType":"geometry","stylers":[{"gamma":"2.08"},{"hue":"#ffa200"}]},{"featureType":"transit.station.airport","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"transit.station.rail","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"transit.station.rail","elementType":"labels.icon","stylers":[{"visibility":"simplified"},{"saturation":"-55"},{"lightness":"-2"},{"gamma":"1.88"},{"hue":"#ffab00"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#bbd9e5"},{"visibility":"simplified"}]}]

        var map = new google.maps.Map(mapElement, mapOptions);
        $rootScope.map = map;
        circle.setMap(map);
        // clustering
        var imgMarkerPath = '/dist/content/img/!shared/main/' + markerColor + '-marker.png';
        var markerStyle = { 'textColor': 'white', 'width': 50, 'height': 33, 'url': imgMarkerPath }; /* 'iconAnchor':[0,0], 'backgroundPosition':'0 0', 'anchor':[3,20], , 'textSize':16 */
        var markerStyles = [markerStyle, markerStyle, markerStyle, markerStyle, markerStyle]
        var mcOptions = { 'gridSize': 25, 'maxZoom': 15, 'zoomOnClick': false, 'averageCenter': true, 'styles': markerStyles };
        var clusterer = new MarkerClusterer(map, [], mcOptions);
        clusterer.setIgnoreHidden(true);
        var myInfoWindow;
        map.euroviaCircle = circle;
        map.euroviaClusterer = clusterer;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    var clientPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    map.setCenter(clientPosition);
                });
        }

        google.maps.event.addListenerOnce(map, 'idle', function () {
            progress.set(3);
        });

        google.maps.event.addListener(map, 'click', function (event) {
            if (myInfoWindow) { myInfoWindow.close(); }
        });

        google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
            progress.set(4);
        });

        google.maps.event.addListener(clusterer, 'clusterclick', function (cluster, event) {
            var list = cluster.markers_.map(function (marker) { return "<li><a class='info-popup' href='#' onclick=\"openMapPopup('" + marker.url + "');return false;\">" + marker.title + "</a></li>"; });
            var offset = new google.maps.Size(0, -10);
            var popupHtml = "<ul style='max-height:200px;list-style-type: none;padding-left: 0;'>";
            list.forEach(function (el) { popupHtml += el; });
            popupHtml += "</ul>";
            if (myInfoWindow) { myInfoWindow.close(); }
            myInfoWindow = new google.maps.InfoWindow({ content: popupHtml, position: cluster.center_, pixelOffset: offset });
            myInfoWindow.open(cluster.map_);
            if (event) event.stopPropagation();
        });

        return map;
    }]);

    app.service("entitiesData", ['$location', '$http', '$log', '$rootScope', 'emap', 'helper', 'infoWindow',
        function ($location, $http, $log, $rootScope, emap, helper, infoWindow) {
            var get = function () {
                var myTime = (new Date()).getTime();
                var myUrl = $location.url() + "?time=" + myTime; // adding time in order do not cache the page (back button fix in browser)
                //var req = { 'method': 'GET', 'url': $location.url() + "?time=" + myTime, 'headers': { 'Cache-Control': 'no-cache, max-age=0, must-revalidate, no-store', 'Pragma': 'no-cache' } }
                // the caller's page url
                return $http.get(myUrl)
                    .then(function (response) { // success
                        var data = {};
                        try {
                            data = angular.fromJson(response.data);
                        } catch (e) { console.log(e); }
                        //data = angular.fromJson(response.data);
                        return data;
                    }, function (xhr, ajaxOptions, thrownError) { // error
                        $log.log("error");
                        $log.log(thrownError);
                        throw thrownError;
                    });
            };

            var createMarker = function (entity, color) {
                var marker = new google.maps.Marker({
                    map: emap,
                    position: new google.maps.LatLng(entity.lat, entity.lng),
                    title: entity.label,
                    url: entity.url,
                    activities: JSON.parse(entity.activities),
                    icon: "/dist/content/img/!shared/main/" + color + "-marker.png"
                });
                marker.entityId = entity.id;

                google.maps.event.addListener(marker, 'click', function () {
                    if (currentMarker) {
                        currentMarker.setIcon("/dist/content/img/!shared/main/" + color + "-marker.png");
                    }
                    marker.setIcon("/dist/content/img/!shared/main/" + color + "-marker-focused.png");
                    emap.panTo(marker.getPosition());
                    currentMarker = marker;

                    //Add blur effect
                    //$("#gmaps").addClass("blurred");
                    $http.get(entity.url + "?SMCL=" + SMCL) //REMOVE AFTER SMCL !!
                        .then(function (response) {
                            $(".giwContainer").html(response.data); // success
                        }, function (response) {
                            $(".giwContainer").html(response.data); // error
                        })
                        .then(function () {
                            infoWindow.show(true); // finally
                        });
                });
                if (marker.map && marker.map.euroviaClusterer) {
                    var myClusterer = marker.map.euroviaClusterer;
                    myClusterer.addMarker(marker, true);
                    /*google.maps.event.addListener(marker, 'visible_changed', function(){
                       if (marker.map && marker.map.euroviaClusterer) {
                          var theMap = marker.map;
                          var myClusterer = marker.map.euroviaClusterer;
                          if ( marker.getVisible() ) {
                                  myClusterer.addMarker(marker, true);
                          } else {
                                  myClusterer.removeMarker(marker, true);
                                  marker.setMap(theMap);
                          }
                       }
                    });*/
                }
                //markers.push(marker);
                return marker;
            };

            var createMarkers = function (entities) {
                if (!entities) { return []; }
                var markers = [];
                angular.forEach(entities, function (item) {
                    var marker = createMarker(item, markerColor);
                    markers.push(marker);
                });

                return markers;
            };

            var filterMarkersByActivity = function (markers, activities) {
                if (!activities || activities.length === 0) {
                    angular.forEach(markers, function (m) {
                        m.setVisible(true);
                    });
                }
                else {
                    angular.forEach(markers, function (m) {
                        if (helper.intersectsWith(m.activities, activities)) {
                            m.setVisible(true);
                        } else {
                            m.setVisible(false);
                        }
                    });
                }

                if ($rootScope.map && $rootScope.map.euroviaClusterer) {
                    $rootScope.map.euroviaClusterer.repaint();
                }
            };
            var exposedAPI = {
                'get': get,
                'createMarkers': createMarkers,
                'filterMarkersByActivity': filterMarkersByActivity
            };
            return exposedAPI;
        }]);

    app.controller('generalController', ['$scope', 'infoWindow', 'progress', '$rootScope', '$timeout',
        function ($scope, infoWindow, progress, $rootScope, $timeout) {
            $scope.close = infoWindow.close;
            $scope.$watch('defaultRadius', function (newValue) {
                if ($rootScope.location && !($rootScope.location.radius) && newValue) {
                    $rootScope.location.radius = parseInt(newValue);
                }
            });
            $scope.$watch('defaultPlace', function (newValue) {
                if ($rootScope.location && !($rootScope.location.place) && newValue) {
                    $rootScope.location.place = newValue;
                }
            });
            $scope.$watch('defaultCenter', function (newValue) {
                if ($rootScope.location && !($rootScope.location.center) && newValue) {
                    $rootScope.location.center = newValue;
                }
            });
            //$scope.defaultCenter = infoWindow.defaultCenter;

            /* DEMO STUFF, TO REMOVE */
            var b = true;
            var expiresAfter = 1;
            var millisecondsInDay = 24 * 60 * 60 * 1000;

            $scope.$watch('activityToDisplay', function (newValue) {
                if (parseInt(newValue)) {
                    $timeout(function () {
                        $rootScope.selectedActivities = [parseInt(newValue)];
                        $rootScope.$apply();
                    });
                }
            });

            $("#location").click(function (e) {
                e.preventDefault();
                if (b) {
                    $(this).val("");
                    b = !b;
                }
            });

            $scope.$watch('demo', function (newValue) {
                SMCL = newValue;

                if (newValue === true) {
                    $("#main-menu").remove();

                    $("[title=France]").attr("href", "#").click(function (e) {
                        e.preventDefault();
                    });
                    $(".navbar").css("margin", 0);
                    $(".navbar-collapse").remove();
                    $(".navbar-brand").find("img").css("height", "30px");
                    $(".breadcrumb-container").remove();
                    $("header").not("#header3").css({
                        "border-bottom": "none", "margin-top": 0
                    });
                    $("#top-banner").css("background", "#fff");
                    $("#banner-social").remove();
                    $("#banner-content").remove();
                    $("#top-banner").css({ "margin-bottom": 0 });
                    $(".navbar-brand").attr("href", "#")
                    $("#logo").css("padding-top", "0.5em");
                    $("footer").remove();
                    $("body").css({
                        "margin-top": "0px", "height": "100%"
                    });
                    $("html").css({
                        'overflow': 'hidden'
                    });
                    $("#filters-container").children().last().remove();
                    //$("#filters-container").children().last().html("");
                    //$("#map3").css({ "height": "calc(100% - 8em)", "top": "62px" });
                    $("#map3").css({
                        "height": "101%"
                    });
                    $("#siteContainer").css("margin-top", "30px");
                    google.maps.event.trigger($("#gmaps")[0], 'resize');

                    $("#location").click(function (e) {
                        e.preventDefault();

                        if (b) {
                            $(this).val("");
                            b = !b;
                        }
                    });
                }
            });
            /* END DEMO STUFF, TO REMOVE */

            $scope.switchList = function (e) {
                e.preventDefault();
                var href = $(e.target).closest('a').attr("data-link");
                if (href) {
                    window.location.href = href;
                }
            };
            progress.set(1);
        }]);

    app.controller('listController', ['$scope', '$timeout', '$rootScope',
        function ($scope, $timeout, $rootScope) {
            $rootScope.$watch('data.allEntities', function (newValue) {
                if ($scope.entities !== newValue) {
                    $timeout(function () {
                        $scope.entities = newValue;
                        $scope.$apply();
                    });
                }
            });

            $scope.openEntityData = function (e) {
                e.preventDefault();
                var href = $(e.delegateTarget).attr('data-href');
                if (href) {
                    if (this.isPopup) {
                        window.openMapPopup(href);
                    }
                    else {
                        window.location.href = href;
                    }
                }
            };
        }]);

    app.controller('mapController', ['$scope', '$timeout', '$rootScope', '$filter', 'entitiesData',
        function ($scope, $timeout, $rootScope, $filter, entitiesData) {
            $rootScope.$watch('data.allEntities', function (newValue) {
                if (newValue) {
                    $timeout(function () {
                        $scope.markers = entitiesData.createMarkers(newValue);
                        if ($scope.displayItem) {
                            var displayMarker = $filter('filter')($scope.markers, { entityId: $scope.displayItem }, true);
                            if (displayMarker && displayMarker.length && displayMarker.length > 0) {
                                var myMarker = displayMarker[0];
                                new google.maps.event.trigger(myMarker, 'click');
                                if (myMarker.map) { myMarker.map.setZoom(10); }
                            }
                        }
                        $scope.$apply();
                    });
                }
            });
            $rootScope.$watch('selectedActivities', function (newValue) {
                if (newValue && $scope.markers) {
                    entitiesData.filterMarkersByActivity($scope.markers, newValue);
                }
            });
        }]);

    app.filter('activitiesLabels', ['helper', function (helper) {
        return function (idString, allActivities) {
            var currentActivities = helper.csvToIntArray(idString);
            if (!currentActivities || !allActivities) return;
            var filtered = helper.idsToNames(currentActivities, allActivities, true, ", ");
            return filtered;
        };
    }]);

    app.filter('entityDistance', ['helper', function (helper) {
        return function (entity, center) {
            var distance = 0;
            try {
                var entityLocation = new google.maps.LatLng(entity.lat, entity.lng);
                var distanceM = google.maps.geometry.spherical.computeDistanceBetween(entityLocation, center);
                distance = distanceM / 1000;
            } catch (e) {
                // just not add the entity to the result
            }
            return distance;
        };
    }]);

    app.filter('withActivities', ['helper', function (helper) {
        return function (entities, activitiesIds) {
            if (!entities) return [];
            var filtered = [];
            if (activitiesIds) {
                if (activitiesIds.constructor === Array) {
                    if (activitiesIds.length === 0) {
                        angular.forEach(entities, function (item) {
                            filtered.push(item);
                        });
                    }
                    else {
                        angular.forEach(entities, function (item) {
                            var itemActivitiesIds = helper.csvToIntArray(item.activities);
                            if (itemActivitiesIds && helper.intersectsWith(activitiesIds, itemActivitiesIds)) {
                                filtered.push(item);
                            }
                        });
                    }
                }
                else { // activitiesIds is not an array, but a "defined" object (int!?)
                    angular.forEach(entities, function (item) {
                        var itemActivitiesIds = helper.csvToIntArray(item.activities);
                        if (itemActivitiesIds && (itemActivitiesIds.indexOf(activitiesIds) > -1)) {
                            filtered.push(item);
                        }
                    });
                }
            }
            else { // activitiesIds is not valid (defined), return all entities
                angular.forEach(entities, function (item) {
                    filtered.push(item);
                });
            }
            return filtered;
        };
    }]);

    app.filter('inCircle', [function () {
        return function (entities, center, radius) {
            if (!entities) return;
            var filtered = [];
            angular.forEach(entities, function (entity) {
                try {
                    var entityLocation = new google.maps.LatLng(entity.lat, entity.lng);
                    var distanceM = google.maps.geometry.spherical.computeDistanceBetween(entityLocation, center);
                    var isInside = ((distanceM / 1000) <= radius);
                    if (isInside) {
                        filtered.push({ 'entity': entity, 'distance': distanceM });
                    }
                } catch (e) {
                    // just not add the entity to the result
                }
            });
            filtered.sort(function (a, b) { return a.distance - b.distance; });

            return filtered.map(function (item) { return item.entity; });
        };
    }]);

    app.controller('activitiesController',
        ['$scope', 'emap', '$timeout', 'progress', '$log', '$rootScope',
            function ($scope, emap, $timeout, progress, $log, $rootScope) {
                $scope.allChecked = true;
                $scope.$watch('activities|filter:{selected:true}', function (newValue) {
                    if (newValue) {
                        var selectedActivities = newValue.map(function (activity) { return activity.id; });
                        if ($rootScope.selectedActivities !== selectedActivities) {
                            $timeout(function () {
                                $rootScope.selectedActivities = selectedActivities;
                                $rootScope.$apply();
                            });
                        }
                        if ($scope.activities.length === newValue.length)
                            $scope.allChecked = true;
                    } else {
                        $scope.allChecked = false;
                    }
                }, true);

                var selectActivities = function (selectedIds, activities) {
                    if (!activities) return;

                    if (selectedIds) {
                        if (selectedIds.constructor === Array) {
                            angular.forEach(activities, function (item) {
                                item.selected = selectedIds.indexOf(item.id) > -1;
                            });
                        }
                        else { // selectedIds is not an array, but a "defined" object (int!?)
                            angular.forEach(activities, function (item) {
                                item.selected = (item.id === selectedIds);
                            });
                        }
                    }
                    else {// selectedIds is not valid (defined)
                        angular.forEach(activities, function (item) {
                            item.selected = true;
                        });
                    }
                };

                $rootScope.$watch('data.allActivities', function (newValue) {
                    if (newValue) {
                        $timeout(function () {
                            if (newValue !== $scope.activities) {
                                $scope.activities = newValue;
                                if ($rootScope.selectedActivities)
                                    selectActivities($rootScope.selectedActivities, $scope.activities);
                                $scope.$apply();
                            }
                        });
                    }
                }, true);

                $rootScope.$watch('selectedActivities', function (newValue) {
                    if (newValue && $scope.activities) {
                        $timeout(function () {
                            selectActivities(newValue, $scope.activities);
                            $scope.$apply();
                        });
                    }
                }, true);
                $scope.checkAll = function () {
                    angular.forEach($scope.activities, function (a) {
                        a.selected = $scope.allChecked;
                    });
                };

                progress.set(6);
            }]);

    app.controller('locationMapController', ['$scope', 'emap', 'geo', 'progress', '$rootScope',
        function ($scope, emap, geo, progress, $rootScope) {
            $scope.map = emap;
            var gmarkers = [];
            var i = 0;

            $rootScope.$watch('location.radius', function (newValue) {
                if (newValue) { zoomTo(newValue); }
            });
            $rootScope.$watch('location.point', function (newValue) {
                if (newValue) {
                    removeMarkers();
                    centerTo(newValue);
                    var marker = new google.maps.Marker({
                        position: $scope.map.euroviaCircle.center,
                        map: $scope.map,
                        icon: "/dist/content/img/!shared/main/blue_marker.png"
                    });
                    gmarkers.push(marker);
                }
            });

            function removeMarkers() {
                for (i = 0; i < gmarkers.length; i++) {
                    gmarkers[i].setMap(null);
                }
            }

            var fitMapBounds = function () {
                $scope.map.euroviaCircle.setMap(null);
                $scope.map.fitBounds($scope.map.euroviaCircle.getBounds());
                $scope.map.euroviaCircle.setMap($scope.map);
            };

            var centerTo = function (center) {
                if (!center) return;
                $scope.map.euroviaCircle.center = center;
                fitMapBounds();
            };

            var zoomTo = function (radiusKm) {
                if (!radiusKm) return;
                var radius = radiusKm * 1000; // radius in meters - standard for gmaps
                $scope.map.euroviaCircle.radius = radius;
                fitMapBounds();
            };

            progress.set(7);
        }]);

    app.controller('locationListController', ['$scope', '$rootScope',
        function ($scope, $rootScope) {
            //$rootScope.$watch('radius', function (newValue) {
            //    if (newValue) zoomTo(newValue);
            //});
            //$rootScope.$watch('point', function (newValue) {
            //    if (newValue) centerTo(newValue);
            //});

            //var centerTo = function (center) {
            //    if (!center) return;
            //    $scope.map.euroviaCircle.center = center;
            //    fitMapBounds();
            //};

            //var zoomTo = function (radiusKm) {
            //    if (!radiusKm) return;
            //    var radius = radiusKm * 1000; // radius in meters - standard for gmaps
            //    $scope.map.euroviaCircle.radius = radius;
            //    fitMapBounds();
            //};
        }]);

    app.config(['$locationProvider', '$httpProvider', function ($locationProvider, $httpProvider) {
        // to have the correct relative $location.path()
        $locationProvider.html5Mode({
            enabled: true, requireBase: false
        });
        // to have a "correct" AJAX request when $http.get()
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
        $httpProvider.defaults.cache = false;
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {
            };
        }
        // disable IE ajax request caching
        $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
    }]);

    // Directive for an indeterminate (tri-state) checkbox.
    // Based on the examples at http://goo.gl/nDUl2A

    app.directive('indeterminateCheckbox', [function () {
        return {
            scope: true,
            require: '?ngModel',
            link: function (scope, element, attrs, modelCtrl) {
                var childList = attrs.childList;
                var property = attrs.property;

                // Bind the onChange event to update children
                element.bind('change', function () {
                    scope.$apply(function () {
                        var isChecked = element.prop('checked');

                        // Set each child's selected property to the checkbox's checked property
                        angular.forEach(scope.$eval(childList), function (child) {
                            child[property] = isChecked;
                        });
                    });
                });

                // Watch the children for changes
                scope.$watch(childList, function (newValue) {
                    var hasChecked = false;
                    var hasUnchecked = false;

                    // Loop through the children
                    angular.forEach(newValue, function (child) {
                        if (child[property]) {
                            hasChecked = true;
                        } else {
                            hasUnchecked = true;
                        }
                    });

                    // Determine which state to put the checkbox in
                    if (hasChecked && hasUnchecked) {
                        element.prop('checked', false);
                        element.prop('indeterminate', true);
                        if (modelCtrl) {
                            modelCtrl.$setViewValue(false);
                        }
                    } else {
                        element.prop('checked', hasChecked);
                        element.prop('indeterminate', false);
                        if (modelCtrl) {
                            modelCtrl.$setViewValue(hasChecked);
                        }
                    }
                }, true);
            }
        };
    }]);

    app.directive('spaceAfter', [function () {
        'use strict';
        return function (scope, element) {
            if (!scope.$last) {
                element.after('&#32;'); // code for space instead of &nbsp;
            }
        };
    }]);
})(angular.module('entitiesApp', []));

/*
 * object.watch polyfill
 *
 * 2012-04-03
 *
 * By Eli Grey, http://eligrey.com
 * Public Domain.
 * NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
 */

// object.watch
if (!Object.prototype.watch) {
    Object.defineProperty(Object.prototype, "watch", {
        enumerable: false,
        configurable: true,
        writable: false,
        value: function (prop, handler) {
            var oldval = this[prop],
                newval = oldval,
                getter = function () {
                    return newval;
                },
                setter = function (val) {
                    oldval = newval;
                    return newval = handler.call(this, prop, oldval, val);
                };
            if (delete this[prop]) { // can't watch constants
                Object.defineProperty(this, prop, {
                    get: getter,
                    set: setter,
                    enumerable: true,
                    configurable: true
                });
            }
        }
    });
}

// object.unwatch
if (!Object.prototype.unwatch) {
    Object.defineProperty(Object.prototype, "unwatch", {
        enumerable: false
        , configurable: true
        , writable: false
        , value: function (prop) {
            var val = this[prop];
            delete this[prop]; // remove accessors
            this[prop] = val;
        }
    });
}